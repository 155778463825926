<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 text-light font-weight-bold"
          style="font-size: 3.5vw"
        >
          Let’s start playing <br />
          the game to better <br />
          your future
          <!-- <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          /> -->
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->

      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <!-- Brand logo-->
        <b-link style="position: absolute; top: 0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt="" class="logo" />
        </b-link>
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bold text-muted" title-tag="h2">
            Welcome to The Hustle Lab! 👋
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login" autocomplete="off">
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
                class="form-group light-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  class="inner-addon left-addon"
                  rules="required|email"
                >
                  <feather-icon icon="MailIcon" size="18" />
                  <b-form-input
                    id="login-email"
                    v-model="loginForm.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                    autocomplete="off"
                    autocorrect="off" 
                    autocapitalize="off"
                    class="light-label custom-form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between light-label">
                  <label for="login-password" class="light-label">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge inner-addon"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <feather-icon icon="LockIcon" size="18" />
                    <b-form-input
                      id="login-password"
                      v-model="loginForm.password"
                      :state="errors.length > 0 ? false : null"
                      class="custom-form-control password-addon light-label"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <!-- <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append> -->
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-link
                :to="{ name: 'auth-forgot-password' }"
                class="float-right mb-2"
              >
                <small class="text-light">Forgot Password?</small>
              </b-link>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="rounded-pill shadow-none"
                style="background-color: #e84185 !important"
                block
              >
                Login
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Don’t have an account? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span style="color: #e84185">&nbsp;Sign up</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAuth } from '@/utils/auth';

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "admin",
      userEmail: "admin@demo.com",
      sideImg: require("@/assets/images/pages/login-v2.svg"),

      // validation rules
      required,
      email,
      loginForm: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          const payload = { query: this.loginForm };
          this.$store.dispatch("users/login", payload).then((res) => {
             if(this.authUser().user && this.authUser().user.type == 2){
              this.$store.commit('appConfig/UPDATE_SKIN', 'dark')
            }else {
              this.$store.commit('appConfig/UPDATE_SKIN', 'light')
            }
            if(res.user.school_status){
              localStorage.setItem("userData", JSON.stringify(res.user));
              localStorage.setItem(
                useJwt.jwtConfig.storageTokenKeyName,
                res.access_token
              );
              this.$router.push("dashboard");
            }else{
              this.$router.push("inactive_school")
            }
          }).catch(error => {
              if (error.response) {
                this.$swal({
                  icon: 'error',
                  title: `<h4>${error.response.data.message}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: '#E84185',
                  allowOutsideClick: true
                });
              }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  font-family: "Inter", sans-serif !important;
}
.logo {
  width: 200px;
}
@media (max-width: 992px) {
  .logo {
    width: 100px;
  }
  .auth-inner {
    display: flex !important;
    justify-content: center !important;
  }
}
.auth-wrapper {
  background: url("../../../assets/images/banner/dark_bakground.png") no-repeat;
  background-size: cover;
}
.auth-bg {
  background-color: #5a5a5a32 !important;
  color: #fff !important;
}

.custom-form-control {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 1px solid #888 !important;
  height: 50px;
}
.inner-addon {
  position: relative;
}
.inner-addon .feather-mail,
.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}
.left-addon input {
  padding-left: 40px;
  color: #fff !important;
}
.password-addon {
  padding-left: 40px !important;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  color: #fff !important;
}
</style>
